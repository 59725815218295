import * as React from 'react';

interface Props {}
const PriceDedicatedSampleTable: React.FC<Props> = () => {
    return (
    <>
    <div className='font-bold text-lg'>
      Hourly Price per GPU
    </div>
    <table style={{minWidth: "400px"}}>
      <thead className="bg-sky-50 dark:bg-slate-500" style={{borderBottom: "1px solid black"}}>
        <tr style={{textAlign: "left"}}>
          <th style={{padding: "5px"}}>Hardware</th>
          <th className="text-sm">Quantity</th>
          <th className="text-sm">$/hour</th>
        </tr>
      </thead>
      <tbody>
        <tr style={{textAlign: "left"}}>
          <td className="text-sm" style={{padding: "5px"}}>A100-40GB</td>
          <td className="text-sm">1</td>
          <td className="text-sm">$1.50</td>
        </tr>
        <tr style={{textAlign: "left"}}>
          <td className="text-sm" style={{padding: "5px"}}>H100-80GB</td>
          <td className="text-sm">1</td>
          <td className="text-sm">$2.50</td>
        </tr>                    
        <tr style={{textAlign: "left"}}>
          <td className="text-sm" style={{padding: "5px"}}>...</td>
          <td className="text-sm">...</td>
          <td className="text-sm">...</td>
        </tr>
        <tr style={{textAlign: "left"}}>
          <td className="text-sm" style={{padding: "5px"}}>...</td>
          <td className="text-sm">...</td>
          <td className="text-sm">...</td>
        </tr>
      </tbody>
    </table>
    </>
    );
}

export default PriceDedicatedSampleTable;
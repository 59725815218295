import { ServerlessDeployment, DedicatedDeployment, Usage} from "react-app-env";
import Grid from '@mui/material/Grid2';
import * as React from 'react';
import { styled } from '@mui/system';
import { Tabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';
import Box from '@mui/material/Box';
import { CopyBlock, dracula } from "react-code-blocks";
import ChatPaneStream from "components/views/common/ChatPaneStream";
import Typography from '@mui/material/Typography';
import { NavLink } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';


const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#80BFFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Tab = styled(BaseTab)`
  font-family: 'IBM Plex Sans', sans-serif;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${blue[200]};
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${blue[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(BaseTabPanel)(
  ({ theme }) => `
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  padding: 20px 12px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  border-radius: 0 0 12px 12px;
  opacity: 0.6;
  `,
);

const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
  min-width: 400px;
  background-color: ${theme.palette.mode === 'dark' ? grey[700] : blue[500]};
  border-radius: 12px 12px 0 0;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
  `,
);

interface propType { 
  data: Array<ServerlessDeployment | DedicatedDeployment>, 
  usagesData: Array<Usage>, 
  defaultTab?: string
}

const ModelUsagesPane: React.FC<propType> = ({data, usagesData, defaultTab}) => {
  const [chatData, setChatData] = React.useState<ServerlessDeployment | DedicatedDeployment>();

  
  React.useEffect(() => {
    // get the first item for chat data
    if(data.length !== 0 && Object.keys(data).length !== 0) {
      setChatData(data[0]);
    }
  }, [data]);

  const metadataUsage = usagesData?.filter((usage) => usage.type === 'metadata')[0]?.content;
  const curlUsage = usagesData?.filter((usage) => usage.type === 'curl')[0]?.content;
  const pythonUsage = usagesData?.filter((usage) => usage.type === 'python')[0]?.content;
  const javascriptUsage = usagesData?.filter((usage) => usage.type === 'javascript')[0]?.content;

  return (
    <>
      {data !== null ? (
        <>
          <Grid container spacing={0}/* style={{ maxHeight: "500px"}}*/>

            {metadataUsage &&
              <Stack direction="column" justifyContent="start" spacing={1} style={{}}>
                  <Typography gutterBottom variant="subtitle1" component="div">
                      <span className="mr-2">Base Url: </span>
                      <CopyBlock
                        text={JSON.parse(metadataUsage).base_url}
                        language={"bash"}
                        showLineNumbers={false}
                        wrapLongLines={false}
                        theme={dracula}
                        customStyle={{ minWidth: "520px", display: "inline-block"}}
                      />
                  </Typography>

                <Typography gutterBottom variant="subtitle1" component="div">
                  <span>Parasail API key: Create one</span>
                  <NavLink to={`/keys`} /*state={dedicatedDeployment || {}} */ >
                    <Button variant="text" size="small" style={{textTransform :"none", minWidth: "unset", fontWeight: 400,
                      fontSize: "1rem", lineHeight: "1.75", letterSpacing: "0.00938em"}}>
                      here
                    </Button>
                  </NavLink>
                  then reuse it for all your batch API calls.
                </Typography>                
              </Stack>
            }

            { (pythonUsage || curlUsage || javascriptUsage) && /* Usage w/ tabs */
            <Grid
              size={{ xs: 12 }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "100%"}}>
                <Tabs defaultValue={defaultTab || "0"}>
                  <TabsList style={{borderRadius: 'unset'}}>
                    { chatData && <Tab value="0">Chat</Tab>}
                    <Tab value="1">Python</Tab>
                    { javascriptUsage && <Tab value="2">Javascript</Tab> }
                    <Tab value="3">cURL</Tab>
                  </TabsList>
                  { chatData &&
                    <TabPanel
                      value="0"
                      style={{ minHeight: "400px", textAlign: "left", border: "none", opacity: 'unset' }}
                    >
                      {/* <ChatPane data={data} /> */}
                      <ChatPaneStream data={chatData} />
                    </TabPanel>
                  }
                  <TabPanel
                    value="1"
                    style={{ minHeight: "400px", textAlign: "left", border: "none", opacity: 'unset' }}
                  >
                    <CopyBlock
                      text={pythonUsage}
                      language={"python"}
                      showLineNumbers={true}
                      wrapLongLines={true}
                      theme={dracula}
                    />
                  </TabPanel>
                  { javascriptUsage && 
                    <TabPanel
                      value="2"
                      style={{ minHeight: "400px", textAlign: "left", border: "none", opacity: 'unset' }}
                    >
                      <CopyBlock
                        text={javascriptUsage}
                        language={"javascript"}
                        showLineNumbers={true}
                        wrapLongLines={true}
                        theme={dracula}
                      />
                    </TabPanel>
                  }
                  <TabPanel
                    value="3"
                    style={{ minHeight: "400px", textAlign: "left", border: "none", opacity: 'unset' }}
                    
                  >
                    <CopyBlock
                      text={curlUsage}
                      language={"curl"}
                      showLineNumbers={true}
                      wrapLongLines={true}
                      theme={dracula}
                      // customStyle={{ maxHeight: '400px' , overflow: 'hidden',  overflowY: 'scroll', border: '1px solid red'}}
                    />
                  </TabPanel>
                </Tabs>
              </Box>
            </Grid>
            }
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default ModelUsagesPane;
import * as React from 'react';
import { useLoaderData } from "react-router-dom";

// Parasail imports
import PageHeader from "components/PageHeader";
import { Logger } from "utils/Logger";
import ModelUsagesPane from "components/views/common/ModelUsagesPane";

const DocsViewBatch = () => {
  const [currentBatchData, setCurrentBatchData] = React.useState<any>();
  const batchData = useLoaderData() as any;

  React.useEffect(() => {
    Logger.log(batchData);

    // save batch data from route loader
    if (batchData) {
      setCurrentBatchData(batchData as any);
    }
  }, [batchData]);

  return (
    <>
        <div className='p-2'>

            <p className='mb-3'>
                Batch processing is the most efficient way to execute thousands or millions of inference requests.
                Parasail offers an API that is drop-in compatible with <a
                href="https://platform.openai.com/docs/libraries">OpenAI
                client libraries</a>.
            </p>

            <p className='mt-3'>
                Parasail supports offline batch inferencing against any model we're able to create a Dedicated
                deployment for. This means most models available on HuggingFace, including a huge variety of chat models
                (including
                fine-tunes), embedding models, multi-modal models, and more.
            </p>

            <p className='mt-3'>
                The process is simple. Write your inference requests to a input file, submit that file as a batch,
                wait for it to complete, then download the output file.
            </p>

            <strong>
            <p className='mt-3'>
                Max requests per batch: {batchData?.limits.maxInputLineCount}
            </p>
            <p>
                Max input file size: {batchData?.limits.maxInputSize / 1024 / 1024} MB
            </p>
            </strong>

            <p className='mt-3'>
            Submit multiple batches to process more requests.
            </p>

            <PageHeader title="Create Input file"/>
            <p className="mb-3">
                The first step is to create a batch input file. We support the OpenAI batch format.
            </p>

            <h2 className='font-bold'>Chat Completion</h2>
            <p className="mb-3">
                <a className="text-blue-500" href="/examples/batch-chat-example.jsonl">Example Chat Completion batch
                    input file (.jsonl)
                </a> and example <a className="text-blue-500" href="/examples/batch-chat-example-output.jsonl">output
                (.jsonl)</a>.
            </p>

            <p className="mb-3">
                Creating an input file programmatically is simple. Simply take the standard interactive request body and
                wrap it in a structure that specifies what type of request it is.
            </p>
            <p className="mb-3">
                <strong>Important! </strong> Each request must have a unique identifier (custom_id). You will
                use this value to match responses to requests. The responses may be in a different order than the
                requests.
            </p>
            {currentBatchData && currentBatchData?.batchUsageCreateInput?.length !== 0 &&
                <ModelUsagesPane data={[]} usagesData={batchData?.batchUsageCreateInput} defaultTab="1"/>
            }

            <h2 className='font-bold'>Embeddings</h2>
            <p className="mb-3">
                <a className="text-blue-500" href="/examples/batch-embedding-example.jsonl">Example Embedding batch
                    input file (.jsonl)
                </a> and example <a className="text-blue-500" href="/examples/batch-embedding-example-output.jsonl">output
                (.jsonl)</a>
            </p>
            <p className="mb-3">
                <strong>Note:</strong> we recommend using the <a className="text-blue-500"
                                                                 href="https://platform.openai.com/docs/api-reference/embeddings/create#embeddings-create-encoding_format">base64
                encoding format</a> because it is far more space efficient than the
                default float format used by the OpenAI protocol. This does require a small post-processing step of
                decoding
                the base64-encoded array, but this format uses 4x fewer bytes for an identical result. Our examples use
                this format, as does the
                official OpenAI client.
            </p>

            <h2 className='font-bold'>Multi Modal</h2>
            <p className="mb-3 italic">Example coming soon!</p>

            <p className="mb-3">
                We support many multi-modal models.
            </p>

            <PageHeader title="Submit Batch"/>
            <p className="mb-3"><strong>Using our <a href="/batch" className="text-blue-500">Batch UI</a>:</strong></p>
            <p className="mb-3">Click the "Create batch" button then drag-and-drop the input file you created above,
                or a downloaded copy of one of our examples.</p>

            <p className="mb-3"><strong>Programmatically using our OpenAI-compatible API:</strong></p>

            <p className="mb-3">The OpenAI protocol has two steps. First upload the input file via the Files API, then
                call the Batch API create a batch that targets that uploaded file.
                This call returns a object that contains the batch ID. Save this for later steps.
            </p>
            {currentBatchData && currentBatchData?.batchUsageSubmit?.length !== 0 &&
                <ModelUsagesPane data={[]} usagesData={batchData?.batchUsageSubmit} defaultTab="1"/>
            }

            <PageHeader title="Check on Progress (optional)"/>
            <p className="mb-3">State and progress of submitted it can be tracked in
                the <a href="/batch" className="text-blue-500">Batch UI</a> or polled programmatically.
            </p>
            <p className="mb-3"><strong>Programmatically using our OpenAI-compatible API:</strong></p>
            {currentBatchData && currentBatchData?.batchUsageCheckStatus?.length !== 0 &&
                <ModelUsagesPane data={[]} usagesData={batchData?.batchUsageCheckStatus} defaultTab="1"/>
            }

            <PageHeader title="Retrieve Results"/>
            <p className="mb-3">
                Once a batch completes the responses appear in an output file. Use the download output button
                in the <a href="/batch" className="text-blue-500">Batch UI</a>.
            </p>
            <p className="mb-3">
                If there are any requests that failed (for example because they are invalid) then they will appear in a
                separate Errors file.
            </p>
            <p className="mb-3"><strong>Programmatically using our OpenAI-compatible API:</strong></p>
            {currentBatchData && currentBatchData?.batchUsageRetrieve?.length !== 0 &&
                <ModelUsagesPane data={[]} usagesData={batchData?.batchUsageRetrieve} defaultTab="1"/>
            }
        </div>
    </>
  );
};

export default DocsViewBatch;
import * as React from 'react';

interface Props {}

const PriceBatchSampleTableSecondary: React.FC<Props> = () => {
  return (
    <>
    <div className='text-sm italic'>
    Sample pricing for batch processing
    </div>
    <table>
      <thead className="bg-sky-50 dark:bg-slate-500" style={{borderBottom: "1px solid black", textAlign: "left"}}>
        <tr>
          <th style={{padding: "5px"}}>Hardware</th>
          <th className="text-sm">Quantity</th>
          <th className="text-sm">$/hour</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-sm" style={{padding: "5px"}}>A100-40GB</td>
          <td className="text-sm">1</td>
          <td className="text-sm">$1.50</td>
        </tr>
        <tr>
          <td className="text-sm" style={{padding: "5px"}}>H100-80GB</td>
          <td className="text-sm">1</td>
          <td className="text-sm">$2.50</td>
        </tr>                    
        <tr>
          <td className="text-sm" style={{padding: "5px"}}>...</td>
          <td className="text-sm">...</td>
          <td className="text-sm">...</td>
        </tr>
        <tr>
          <td className="text-sm" style={{padding: "5px"}}>...</td>
          <td className="text-sm">...</td>
          <td className="text-sm">...</td>
        </tr>
      </tbody>
    </table>
    </>
    );
}

export default PriceBatchSampleTableSecondary;